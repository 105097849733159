import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { message } from 'antd'
import { auth0Roles, strapiURL } from '../../../config'
import {
  formatPrice,
  permissionsHandler,
  returnCreditType,
} from '../../../functions'
import { navigate } from 'gatsby'
import { MinusIcon, PlusIcon, StepCheckIcon } from '../../icons'
import { useSelector } from 'react-redux'
import useGMT from '../../../hooks/useGTM'
import globalData from '../../../../globalData.json'
import cookie from 'react-cookies'

let selectedPriceId

const EmployerPurchaseMemberShip = ({
  location,
  user,
  userRole,
  userProfile,
}) => {
  const preSelectedAddons = location.state?.preSelectedAddons
  const [step, setStep] = useState(1)
  const [listingPrices, setListingPrices] = useState([])
  const [checkoutData, setCheckoutData] = useState({})
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [totalAmount, setTotalAmount] = useState(0)
  const [alreadySubscription, setAlreadySub] = useState(null)
  const storeProducts = useSelector(state => state.user).products
  let products = permissionsHandler(storeProducts, user?.role?.id)

  let employerSubscriptionItems =
    globalData.EmployerCatalog.prices?.filter(item => !item.isDiscontinued) ||
    []
  const navbarItems = [
    {
      label: 'Select Plan',
      id: 1,
    },
    {
      label: 'Choose add-ons',
      id: 2,
    },
    {
      label: 'Make payment',
      id: 4,
    },
  ]

  const {
    pushEmployerViewItemEvent,
    pushEmployerSelectItemEvent,
    pushEmployerSelectAddonEvent,
    pushEmployerAddToCartSubscriptionEvent,
    pushEmployerAddToCartAddonEvent,
    pushEmployerCheckoutEvent,
    pushRegisterEvent,
  } = useGMT()

  useEffect(() => {
    window.scrollTo(0, 0)
    if (step === 1) {
      setCheckoutData({})
    }

    if (step === 1) {
      pushEmployerViewItemEvent('2')
    } else if (step === 2 && checkoutData.subscription?.priceId) {
      pushEmployerViewItemEvent('3', checkoutData.subscription?.priceId)
    }
  }, [step])

  useEffect(() => {
    if (step === 1) {
      if (
        checkoutData.subscription?.priceId &&
        selectedPriceId !== checkoutData.subscription?.priceId
      ) {
        pushEmployerSelectItemEvent(checkoutData.subscription?.priceId)
        selectedPriceId = checkoutData.subscription?.priceId
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutData])

  useEffect(() => {
    handleCheckSubscription()
  }, [])

  const handleGTMTracking = addon => {
    pushEmployerSelectAddonEvent(addon)
  }

  const handleCheckSubscription = async () => {
    if (
      !user ||
      (userRole !== auth0Roles.Employer.id &&
        userRole !== auth0Roles.NamedUser.id)
    ) {
      navigate('/')
    }

    const payload =
      userRole === auth0Roles.NamedUser.id
        ? {
            employerId: userProfile.id,
          }
        : {}

    const result = await axios.post(
      strapiURL + '/auth0/user/checkAndCreateActiveSubscription',
      payload
    )

    if (result?.data?.isSubscriptionExist) {
      alert('Great News! We found your active subscription.')
      navigate('/employer-dashboard')
    }

    axios.get(strapiURL + `/user/carts/${user?.user?.id}`).then(response => {
      let cartData = response?.data?.cartData
      setListingPrices(employerSubscriptionItems)
      if (
        products?.subscriptionPurchased &&
        !products?.isSubscriptionCancelled
      ) {
        let _subscription = products?.subscriptionPurchased
        let _sub = employerSubscriptionItems.find(
          item => item.price.priceId === _subscription.price.priceId
        )
        setAlreadySub(_sub)
        stepsHandler(_sub, _sub?.addOns)
      } else if (cartData?.subscription) {
        let _sub = employerSubscriptionItems.find(
          item => item.price.priceId === cartData?.subscription?.priceId
        )
        let addons = [..._sub?.addOns]
        cartData?.currentAddons?.forEach(cd => {
          addons[
            addons.findIndex(item => item.credit_type === cd?.credit_type)
          ] = cd
        })

        stepsHandler(_sub, addons)
      } else {
        if (preSelectedAddons?.featuredJob) {
          let _sub = employerSubscriptionItems[0]
          setStep(2)
          setCheckoutData({
            ...checkoutData,
            subscription: _sub,
            currentAddons: _sub.addOns,
          })
        }
        setLoading(false)
      }
    })
  }

  const stepsHandler = (value, addons) => {
    let _checkoutDataCopy = { ...checkoutData }
    // Subscription Step
    if (step === 1) {
      if (value?.price?.id === _checkoutDataCopy?.subscription?.id) {
        _checkoutDataCopy = {}
      } else {
        _checkoutDataCopy.subscription = value.price
        _checkoutDataCopy.currentAddons = value?.addOns || []
        if (preSelectedAddons) {
          if (preSelectedAddons.resumeSearch) {
            _checkoutDataCopy.currentAddons[0]?.nickname !== 'Featured Job'
              ? (_checkoutDataCopy.currentAddons[0].is_selected = true)
              : (_checkoutDataCopy.currentAddons[0].is_selected = false)
          } else if (preSelectedAddons.featuredJob) {
            const featuredJobAddonIndex =
              _checkoutDataCopy.currentAddons.findIndex(
                item => item.nickname === 'Featured Job'
              )
            _checkoutDataCopy.currentAddons[featuredJobAddonIndex].quantity = 1
          }
        }

        if (addons) {
          if (!preSelectedAddons) {
            _checkoutDataCopy.currentAddons = addons
          }
          setStep(2)
        }
      }
    }

    setCheckoutData(_checkoutDataCopy)
    setLoading(false)
  }

  const quantityHandler = (isPrice, val, addonIndex) => {
    let _currentAddons = [...checkoutData.currentAddons]
    let _currentSubscription = { ...checkoutData.subscription }

    if (isPrice) {
      if (val) {
        _currentSubscription.quantity = val
        setCheckoutData({ ...checkoutData, subscription: _currentSubscription })
      } else {
        setCheckoutData({})
      }
      return
    }
    if (
      returnCreditType(_currentAddons[addonIndex].credit_type).type ===
        'resumeSearch' ||
      returnCreditType(_currentAddons[addonIndex].credit_type).type ===
        'addonSubscription'
    ) {
      if (_currentAddons[addonIndex].is_selected) {
        _currentAddons[addonIndex].is_selected = false
      } else {
        _currentAddons[addonIndex].is_selected = true
      }
    } else {
      _currentAddons[addonIndex].quantity = val
    }

    setCheckoutData({ ...checkoutData, currentAddons: _currentAddons })
  }

  const addonsQuantityHandler = (val, addonIndex) => {
    let _currentAddons = [...checkoutData.currentAddons]
    if (
      returnCreditType(_currentAddons[addonIndex].credit_type).type ===
      'resumeSearch'
    ) {
      if (_currentAddons[addonIndex].is_selected) {
        _currentAddons[addonIndex].is_selected = false
      } else {
        _currentAddons[addonIndex].is_selected = true
      }
    } else if (
      returnCreditType(_currentAddons[addonIndex].credit_type).type ===
      'featuredJob'
    ) {
      if (parseInt(val) <= 0) {
        _currentAddons[addonIndex].is_selected = false
      } else {
        _currentAddons[addonIndex].is_selected = true
      }
      _currentAddons[addonIndex].quantity = val
    } else {
      _currentAddons[addonIndex].quantity = val
    }

    setCheckoutData({ ...checkoutData, currentAddons: _currentAddons })
  }

  const subscriptionQuantityHandler = (price, quantity) => {
    let _currentSubscription = { ...checkoutData.subscription }

    if (price?.price) {
      if (!quantity || parseInt(quantity) <= 0) {
        _currentSubscription = price.price
        _currentSubscription.quantity = 1
        let addOns = price.addOns || []
        setCheckoutData({
          ...checkoutData,
          subscription: _currentSubscription,
          currentAddons: addOns,
        })
      } else {
        setCheckoutData({})
      }
    }
  }

  const featuredJobQuantityHandler = (currentAddon, addonIndex) => {
    let _currentAddons = [...checkoutData.currentAddons]

    if (currentAddon.quantity || currentAddon.is_selected) {
      _currentAddons[addonIndex].is_selected = false
      _currentAddons[addonIndex].quantity = 0
    } else {
      _currentAddons[addonIndex].is_selected = true
      _currentAddons[addonIndex].quantity = 1
    }

    setCheckoutData({ ...checkoutData, currentAddons: _currentAddons })
  }

  useEffect(() => {
    let total = 0
    if (checkoutData.subscription) {
      if (!alreadySubscription) {
        if (
          checkoutData.subscription?.amount &&
          checkoutData.subscription?.quantity
        ) {
          total =
            total +
            parseInt(checkoutData.subscription?.amount) *
              checkoutData.subscription?.quantity
        } else {
          total = total + parseInt(checkoutData.subscription.amount)
        }
      }
    }
    checkoutData?.currentAddons?.forEach(addon => {
      if (
        ((returnCreditType(addon.credit_type).type === 'resumeSearch' ||
          returnCreditType(addon.credit_type).type === 'addonSubscription') &&
          addon.is_selected) ||
        addon.quantity
      ) {
        if (addon.quantity) {
          total = total + parseInt(addon.amount) * addon.quantity
        } else {
          total = total + parseInt(addon.amount)
        }
      }
    })

    setTotalAmount(total)
  }, [checkoutData])

  const userCheckhandler = () => {
    if (alreadySubscription) {
      let selected =
        checkoutData?.currentAddons?.length &&
        checkoutData?.currentAddons.find(
          item => item.quantity || item.is_selected
        )
      if (!selected) {
        message.error('Please select products to continue.')
        return
      }
    }
    setSubmitLoading(true)
    pushEmployerAddToCartAddonEvent(checkoutData.currentAddons)

    pushEmployerCheckoutEvent(
      checkoutData.subscription?.priceId,
      checkoutData.currentAddons
    )

    localStorage.setItem(
      'emp-subscription',
      JSON.stringify({
        subscription: checkoutData.subscription,
        addons: checkoutData.currentAddons,
      })
    )

    let _checkoutPrices = []
    let mode = 'payment'
    let url = '/thankyou/?success=true&session_id={CHECKOUT_SESSION_ID}'
    if (typeof window !== 'undefined') {
      if (!alreadySubscription && checkoutData.subscription.priceId) {
        _checkoutPrices.push({
          price: checkoutData.subscription.priceId,
          quantity: checkoutData.subscription.recurring
            ? 1
            : checkoutData.subscription.quantity
            ? checkoutData.subscription.quantity
            : 1,
        })
        if (checkoutData.subscription.recurring) {
          mode = 'subscription'
        } else {
          url += '&employerRedirect=post'
        }
      }
      if (checkoutData?.currentAddons?.length) {
        for (const item of checkoutData?.currentAddons) {
          if (
            returnCreditType(item.credit_type).type === 'resumeSearch' ||
            returnCreditType(item.credit_type).type === 'addonSubscription'
          ) {
            if (item.is_selected) {
              _checkoutPrices.push({
                price: item.priceId,
                quantity: 1,
              })
              mode = 'subscription'
            }
          } else {
            if (item.quantity) {
              _checkoutPrices.push({
                price: item.priceId,
                quantity: item.quantity,
              })
            }
          }
        }
      }
    }
    axios
      .post(strapiURL + '/stripe/session/checkout', {
        line_items: _checkoutPrices,
        success_url: window.location.origin + url,
        cancel_url: window.location.origin,
        customerEmail: userProfile?.username || '',
        mode: mode,
        customer:
          userRole === auth0Roles.NamedUser.id
            ? user?.parent_customer_id
            : user?.customer_id || '',
        clientId: cookie.load('_ga')?.substring(6),
        sessionId: cookie
          .load(
            process.env.GATSBY_GOOGLE_ANALYTICS_MEASUREMENT_ID?.replace(
              'G-',
              '_ga_'
            )
          )
          ?.split('.')[2],
      })
      .then(res => {
        if (typeof window !== 'undefined') {
          window.history.replaceState(
            '',
            '',
            `${window.location.origin}/thankyou/`
          )
          window.location.href = res?.data?.url
        }
      })
      .catch(err => {
        message.error('Something wrong while checkout.')
        setSubmitLoading(false)
      })
  }
  useEffect(() => {
    if (!loading) {
      axios
        .post(strapiURL + '/carts/updateUserCart', {
          user: user?.user?.id,
          cartData: {
            currentAddons:
              step === 2
                ? checkoutData?.currentAddons?.filter(
                    item => item.is_selected || item.quantity
                  )
                : null,
            subscription: alreadySubscription
              ? null
              : checkoutData.subscription,
          },
        })
        .then(response => {
          // console.log(response)
        })
        .catch(err => {
          // console.log(err)
        })
    }
  }, [checkoutData?.subscription, loading, checkoutData?.currentAddons, step])

  return (
    <>
      <div className="container mx-auto py-12">
        <div className="w-[90%] md:w-4/5 mx-auto">
          <div className="w-full md:w-3/5">
            {/* navbar Area */}
            {step > 0 && (
              <div className="navbar-area">
                <div className="flex items-center relative pb-6">
                  {step > 0 ? (
                    <img
                      onClick={() => {
                        if (step > 1) {
                          if (alreadySubscription) {
                            return
                          }
                          setStep(step - 1)
                        } else {
                          navigate('/')
                        }
                      }}
                      src="/icons/icon-back-red.svg"
                      className="w-4 mr-2 cursor-pointer"
                    />
                  ) : (
                    <div />
                  )}
                  <h1 className="text-lg font-sans font-semibold text-merlot mb-0">
                    Post jobs with Medreps
                  </h1>
                </div>
                <div className="flex items-center">
                  {navbarItems?.map((navItem, navIndex) => {
                    return (
                      <>
                        <div className="membership-step" key={navIndex}>
                          {step >= navItem.id ? (
                            <div className="active h-6 w-6 bg-[#059913] rounded-full flex items-center justify-center">
                              {step === navItem.id ? (
                                <div className="h-2 w-2 bg-white rounded-full" />
                              ) : (
                                <StepCheckIcon />
                              )}
                            </div>
                          ) : (
                            <div className="inactive bg-border h-6 w-6 bg-[#E1E1E1] rounded-full" />
                          )}

                          <div className="absolute">
                            <p className="w-full text-xs font-semibold font-sans w-max mt-4">
                              {navItem.label}
                            </p>
                          </div>
                        </div>
                        {navIndex !== navbarItems?.length - 1 && (
                          <div className="divider w-85 h-px bg-border" />
                        )}
                      </>
                    )
                  })}
                </div>
              </div>
            )}
          </div>
          {/* navbar Area */}
          {loading ? (
            <div
              className="w-3/5 min-h-700px bg-cover bg-center bg-no-repeat mt-16"
              style={{
                backgroundImage: `url(/loaders/employer-products-loader.png)`,
              }}
            />
          ) : (
            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-3/5">
                {/* Steps Area */}
                {/* Step 1 */}
                {step === 1 && (
                  <div className="pt-16 w-full md:w-11/12">
                    <h1 className="font-playfair font-normal text-headingThree mb-2">
                      Choose your job posting plan
                    </h1>
                    <p className="text-sm font-sans">Cancel anytime</p>
                    <div className="options-div">
                      {listingPrices?.map((price, priceIndex) => {
                        let isSelected =
                          checkoutData?.subscription?.id === price?.price?.id

                        return (
                          <div
                            onClick={() => {
                              if (
                                returnCreditType(price.price.credit_type)
                                  .type !== 'singleJob'
                              ) {
                                stepsHandler(price)
                              } else {
                                subscriptionQuantityHandler(
                                  price,
                                  checkoutData?.subscription?.quantity
                                )
                              }
                            }}
                            key={priceIndex}
                            className={`mb-8 single-option bg-white shadow-md flex justify-between items-center p-4 rounded-md cursor-pointer border-2 border-transparent ${
                              isSelected
                                ? 'border-2 border-merlot active-card-shadow'
                                : ''
                            }`}
                          >
                            <div className="flex-1 flex items-center">
                              {isSelected ? (
                                <div className="flex items-center justify-center active bg-merlot h-6 lg:h-9 w-6 lg:w-9 bg-merlot rounded-full flex items-center justify-center">
                                  <img
                                    src="/icons/check-mark.svg"
                                    className="mb-0"
                                  />
                                </div>
                              ) : (
                                <div className="inactive bg-border h-6 lg:h-9 w-6 lg:w-9 bg-[#E1E1E1] rounded-full" />
                              )}

                              <div className="ml-4 flex-1">
                                <p className="font-sans font-bold text-lg mb-0">
                                  {price?.price?.nickname || price?.name}
                                </p>
                                <p className="font-sans text-xs mb-1">
                                  {price?.price?.description}
                                  <span className="text-[#c40000]">
                                    {' '}
                                    {price?.price?.savesUpto
                                      ? `${price?.price?.savesUpto}`
                                      : ''}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="ml-6">
                              <p
                                className={`${
                                  isSelected ? 'text-merlot' : 'text-black'
                                } font-sans font-bold text-lg mb-0 text-right`}
                              >
                                <span>
                                  {formatPrice(price?.price?.amount, 'USD')}
                                </span>
                              </p>
                              <p
                                className={`${
                                  isSelected ? 'text-merlot' : 'text-black'
                                } font-sans font-bold text-lg mb-0`}
                              >
                                <span>{price?.price?.duration}</span>
                              </p>
                              {returnCreditType(price.price.credit_type)
                                .type === 'singleJob' ? (
                                <div className="flex items-center mt-2">
                                  <button
                                    className="p-4 cursor-pointer"
                                    onClick={e => {
                                      if (
                                        checkoutData?.subscription?.quantity
                                      ) {
                                        quantityHandler(
                                          true,
                                          checkoutData?.subscription?.quantity -
                                            1
                                        )
                                      }
                                      e.stopPropagation()
                                    }}
                                  >
                                    <MinusIcon />
                                  </button>
                                  <span className="text-lg mx-2 text-charcoal">
                                    {checkoutData?.subscription?.quantity || 0}
                                  </span>
                                  <button
                                    className="p-4 cursor-pointer"
                                    onClick={e => {
                                      if (
                                        !checkoutData.subscription ||
                                        returnCreditType(
                                          checkoutData?.subscription
                                            ?.credit_type
                                        ).type !== 'singleJob'
                                      ) {
                                        stepsHandler({
                                          ...price,
                                          price: {
                                            ...price.price,
                                            quantity: 1,
                                          },
                                        })
                                        return
                                      }
                                      if (
                                        returnCreditType(
                                          checkoutData?.subscription
                                            ?.credit_type
                                        ).type === 'singleJob'
                                      ) {
                                        quantityHandler(
                                          true,
                                          checkoutData?.subscription?.quantity
                                            ? checkoutData?.subscription
                                                ?.quantity + 1
                                            : 1
                                        )
                                        e.stopPropagation()
                                      }
                                    }}
                                  >
                                    <PlusIcon />
                                  </button>
                                </div>
                              ) : (
                                <div />
                              )}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    <button
                      onClick={() => {
                        if (!checkoutData?.subscription) {
                          message.info(
                            'Please choose a subscription plan to continue.'
                          )
                          return
                        }
                        setStep(2)
                        pushEmployerAddToCartSubscriptionEvent(
                          checkoutData.subscription.priceId
                        )
                      }}
                      className="bg-voyage w-full py-4 text-white font-sans font-bold mb-0"
                    >
                      Next
                    </button>
                  </div>
                )}
                {step === 2 && (
                  <>
                    <div className="pt-16 w-11/12">
                      <h1 className="font-playfair font-normal text-headingThree mb-2">
                        Choose Add-Ons
                      </h1>
                      <p className="text-sm font-sans">
                        Optimize your recruitment with these smart add-ons
                      </p>
                      {checkoutData?.currentAddons?.map(
                        (currentAddon, currIndex) => {
                          if (
                            returnCreditType(currentAddon.credit_type).type ===
                              'resumeSearch' &&
                            products.allowResumeSearch
                          ) {
                            //No need to display resume search add-on, as it is already purchased
                            return
                          }
                          if (
                            returnCreditType(currentAddon.credit_type).type ===
                            'addonSubscription'
                          ) {
                            if (
                              !userProfile?.staffing_firm &&
                              currentAddon.staffing
                            ) {
                              return null
                            }

                            if (
                              userProfile?.staffing_firm &&
                              !currentAddon.staffing
                            ) {
                              return null
                            }

                            const addonSubscriptionItem =
                              products?.addonSubscriptions?.find(
                                priceItem =>
                                  priceItem?.price?.priceId ===
                                  currentAddon.priceId
                              )

                            if (addonSubscriptionItem) {
                              return null
                            }
                          }

                          return (
                            <>
                              <div
                                onClick={() => {
                                  if (
                                    returnCreditType(currentAddon.credit_type)
                                      .type === 'resumeSearch' ||
                                    returnCreditType(currentAddon.credit_type)
                                      .type === 'addonSubscription'
                                  ) {
                                    quantityHandler(false, '', currIndex)
                                  } else if (
                                    returnCreditType(currentAddon.credit_type)
                                      .type === 'featuredJob'
                                  ) {
                                    featuredJobQuantityHandler(
                                      currentAddon,
                                      currIndex
                                    )
                                  }

                                  if (
                                    currentAddon.is_selected ||
                                    currentAddon.quantity === 1
                                  ) {
                                    handleGTMTracking(
                                      checkoutData.currentAddons
                                    )
                                  }
                                }}
                                key={currIndex}
                                className={`mb-8 single-option bg-white shadow-md flex justify-between items-center px-4 py-8 rounded-md cursor-pointer border-2 border-transparent ${
                                  currentAddon.quantity ||
                                  currentAddon.is_selected
                                    ? 'border-2 border-merlot active-card-shadow'
                                    : ''
                                }`}
                              >
                                <div className="flex lg:items-center">
                                  {currentAddon.quantity ||
                                  currentAddon.is_selected ? (
                                    <div>
                                      <div className="flex items-center justify-center active bg-merlot h-9 w-9 bg-merlot rounded-full flex items-center justify-center">
                                        <img
                                          src="/icons/check-mark.svg"
                                          className="mb-0"
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <div className="inactive bg-border h-9 w-9 bg-[#E1E1E1] rounded-full flex items-center justify-center" />
                                    </div>
                                  )}

                                  <div className="ml-4 w-8/12">
                                    <p className="font-sans font-bold text-lg mb-2">
                                      {currentAddon.nickname}
                                    </p>
                                    <p className="font-sans text-[#c40000] text-xs mb-1">
                                      {currentAddon?.red_desc || ''}
                                    </p>
                                    <p className="font-sans text-xs text-black  mb-0">
                                      {currentAddon?.description || ''}
                                    </p>
                                  </div>
                                </div>
                                <div className="flex flex-col justify-center items-center">
                                  <p
                                    className={`${
                                      currentAddon.quantity ||
                                      currentAddon.is_selected
                                        ? 'text-merlot'
                                        : 'text-black'
                                    } font-sans font-bold text-lg mb-0 w-max`}
                                  >
                                    {formatPrice(currentAddon?.amount, 'USD')}
                                    &nbsp;
                                    {currentAddon?.metadata?.price_text || ''}
                                  </p>
                                  {returnCreditType(currentAddon.credit_type)
                                    .type === 'featuredJob' ? (
                                    <div className="flex items-center mt-2">
                                      <button
                                        className="p-4 cursor-pointer"
                                        onClick={e => {
                                          if (currentAddon.quantity) {
                                            addonsQuantityHandler(
                                              currentAddon.quantity - 1,
                                              currIndex
                                            )
                                          }
                                          e.stopPropagation()
                                        }}
                                      >
                                        <MinusIcon />
                                      </button>
                                      <span className="text-lg mx-2 text-charcoal">
                                        {currentAddon.quantity || 0}
                                      </span>
                                      <button
                                        className="p-4 cursor-pointer"
                                        onClick={e => {
                                          addonsQuantityHandler(
                                            currentAddon.quantity
                                              ? currentAddon.quantity + 1
                                              : 1,
                                            currIndex
                                          )
                                          if (currentAddon.quantity === 1) {
                                            handleGTMTracking(
                                              checkoutData.currentAddons
                                            )
                                          }
                                          e.stopPropagation()
                                        }}
                                      >
                                        <PlusIcon />
                                      </button>
                                    </div>
                                  ) : (
                                    <div />
                                  )}
                                </div>
                              </div>
                            </>
                          )
                        }
                      )}
                      <button
                        onClick={() => userCheckhandler()}
                        className="bg-voyage w-full py-4 text-white font-sans font-bold mb-0"
                      >
                        {submitLoading ? 'Loading...' : 'Make payment'}
                      </button>
                    </div>
                  </>
                )}
                {/* Step 1 */}

                {/* Steps Area */}
              </div>
              <div className="w-full md:w-4/12 pt-12">
                <div className="opacity-0 hidden md:block">
                  <h1 className="font-playfair font-normal text-headingThree mb-2">
                    Choose your job posting plan
                  </h1>
                  <p className="text-sm font-sans">Cancel anytime</p>
                </div>
                {totalAmount && (step === 2 || step === 3) ? (
                  <div className="shadow-profileCard px-4 py-8 rounded -mt-2">
                    <p className="text-merlot font-sans font-bold text-lg">
                      Your Purchase
                    </p>
                    {!alreadySubscription && (
                      <div className="mb-3">
                        <p className="font-sans font-bold text-lg mb-0">
                          {checkoutData?.subscription?.nickname}
                        </p>
                        <p className="font-sans text-xs font-normal mb-0">
                          {checkoutData?.subscription?.quantity
                            ? checkoutData?.subscription?.quantity +
                              ' ' +
                              'credit(s) purchased'
                            : ''}
                          <span className="ml-1 font-bold">
                            {checkoutData?.subscription?.amount &&
                              checkoutData?.subscription?.quantity &&
                              formatPrice(
                                checkoutData?.subscription?.amount *
                                  checkoutData?.subscription?.quantity,
                                'Usd'
                              )}
                          </span>
                        </p>
                        <p className="font-sans text-xs font-normal mb-0">
                          {checkoutData?.subscription?.description}
                        </p>
                      </div>
                    )}
                    {checkoutData?.currentAddons
                      ?.filter(item => item.is_selected || item.quantity)
                      .map((addon, addonIndex) => {
                        let addonType = returnCreditType(addon.credit_type).type

                        return (
                          <div className="mb-3" key={addonIndex}>
                            <p className="font-sans font-bold text-lg mb-0">
                              {addon?.nickname}
                            </p>

                            <p className="font-sans text-xs font-normal mb-0">
                              {addonType === 'resumeSearch' ||
                              addonType === 'addonSubscription'
                                ? addon.black_description
                                : addon.quantity + ' ' + 'credit(s) purchased'}
                            </p>
                          </div>
                        )
                      })}
                    <p className="font-sans font-bold text-lg mb-0 mt-6">
                      {formatPrice(totalAmount, 'Usd')}
                    </p>
                  </div>
                ) : (
                  <div />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
export default EmployerPurchaseMemberShip
