import React from 'react'
import Wrapper from '../../components/wrapper'
import EmployerPurchaseMemberShip from '../../components/pageComponents/employerPurchaseMembership'

const EmployerMembership = ({ location }) => {
  return (
    <Wrapper
      location={location}
      hideCTA={true}
      privateRoute
      title="Join MedReps.com | MedReps.com"
      employerFacing={true}
    >
      <EmployerPurchaseMemberShip />
    </Wrapper>
  )
}
export default EmployerMembership
